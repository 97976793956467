import { IWikiModel } from '@/interfaces/wiki-model';

export const cookiesDefinition: IWikiModel = {
   sections: [
       {
           id: 1,
           title: 'Politica de utilizare cookies',
           text: 'Acest site folosește module cookie. <br /> Utilizăm modulele cookie pentru a personaliza conținutul site-ului și pentru a analiza \
           traficul site-ului nostru. <br /> Aceste cookie-uri sunt stabilite numai ca răspuns la acțiunile efectuate de dvs. pe site-ul nostru și reprezintă,\
           de asemenea, o solicitare de informații.<br /> Puteți șterge toate cookie-urile din calculatorul dvs și puteți seta majoritatea browserelor să blocheze\
           plasarea acestora. Aceste cookie-uri nu stochează informații de identificare personală.'
       }
   ]
};