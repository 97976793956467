
import { Component, Vue } from "vue-property-decorator";
import Footer from "@/components/Footer.vue";
import Wiki from "@/components/Wiki.vue";
import { cookiesDefinition } from '../wiki-definitions/cookies-definition';

@Component({
  name: "CookiesPolicy",
  components: {
    Footer,
    Wiki
  }
})
export default class CookiesPolicy extends Vue {
  public cookiesDef = cookiesDefinition;
}
